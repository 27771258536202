<template>
    <table-container>
        <data-iterator
            ref="iterator"
            :api="api"
            :filters.sync="filters"
            @update:filters="updateFilters"
        >
            <template v-slot:filters="{ busy }">
                <table-filters
                    v-model="filters"
                    :fields="headers"
                    :busy="busy"
                    :list-filters="listFilters"
                    @input="updateFilters"
                ></table-filters>
            </template>

            <template v-slot:actions>
                <slot name="actions"></slot>
            </template>

            <template slot-scope="{ items }">
                <d-table
                    :items="items"
                    :headers="headers"
                    :filters.sync="filters"
                    row-action
                    @onRowSelected="onRowSelected"
                >
                    <!-- name column -->
                    <template v-slot:[`item.name`]="{ item }">
                        <v-row
                            justify="space-between"
                            align="center"
                            class="flex-nowrap"
                        >
                            <v-col cols="auto" class="py-0 pr-1 flex-shrink-1">
                                <router-link :to="getEditLink(item)">
                                    {{ item.name }}
                                </router-link>
                            </v-col>
                            <v-col cols="auto" class="py-0 pl-1 hover-unhide">
                                <edit-button
                                    :tooltip="$t('labels.copy_frontend_url')"
                                    icon="content_copy"
                                    xsmall
                                    @execute="copyRestaurantFElink(item)"
                                ></edit-button>
                            </v-col>
                        </v-row>
                    </template>

                    <template v-slot:[`item.fhrsRating`]="{ item }">
                        <div v-if="item.fhrsRating">
                            <div>{{ item.fhrsRating }}</div>
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                    <small class="g-73--text" v-on="on">{{
                                        item.fhrsInspectionDate | transformDate
                                    }}</small>
                                </template>
                                <span>{{
                                    item.fhrsInspectionDate
                                        | toDate('YYYY-MM-DD HH:mm')
                                }}</span>
                            </v-tooltip>
                        </div>
                        <div v-else>-</div>
                    </template>

                    <!-- third party differences -->
                    <template v-slot:[`item.thirdPartyDifferences`]="{ item }">
                        <div v-if="item.thirdPartyDifferences">
                            <div
                                v-for="(type, i) in item.thirdPartyDifferences"
                                :key="i"
                            >
                                {{ $t(`tp_diff.services.${type.service}`) }}:
                                <span v-for="(diff, j) in type.diffs" :key="j">
                                    {{
                                        $t('tp_diff.diff_message', {
                                            key: $t(`tp_diff.keys.${diff.key}`),
                                            local: diff.local,
                                            remote: diff.remote,
                                        })
                                    }}
                                </span>
                            </div>
                        </div>
                        <div v-else>-</div>
                    </template>

                    <!-- isEnabledForRestaurantAdmin -->
                    <template
                        v-slot:[`item.isEnabledForRestaurantAdmin`]="{ item }"
                    >
                        <checkbox-input
                            v-if="showAdminCheckboxes"
                            :value="item.isEnabledForRestaurantAdmin"
                            :disabled="!isSuperAdmin"
                            class="mt-0"
                            hide-details
                            @input="
                                item.isEnabledForRestaurantAdmin
                                    ? confirmRestaurantAdminDisable(item)
                                    : confirmRestaurantAdminEnable(item)
                            "
                        ></checkbox-input>
                    </template>

                    <!-- active -->
                    <template v-slot:[`item.isActive`]="{ item }">
                        <modal-activate-for-today
                            :api="api"
                            :model="item"
                            :disable-toggle="!item.isEnabledForRestaurantAdmin"
                            trigger-class="mt-0"
                            checkbox-toggle
                            confirm-activate
                            @saved="$refs.iterator.updateItems(true)"
                        ></modal-activate-for-today>
                        <span
                            v-if="!item.isActive && item.inactivatedUntil"
                            class="text-body-2 mb-0 red--text font-italic text-no-wrap"
                        >
                            {{ $t('labels.inactivate_for_today') }}
                        </span>
                    </template>

                    <!-- row actions -->
                    <template v-slot:rowActions="{ item }">
                        <edit-button :to="getEditLink(item)"></edit-button>
                        <edit-button
                            :tooltip="$t('labels.delivery_settings')"
                            icon="motorcycle"
                            :to="getEditLink(item, 3)"
                        ></edit-button>
                        <edit-button
                            :tooltip="$t('labels.menu')"
                            icon="restaurant"
                            :to="getEditLink(item, 5)"
                        ></edit-button>
                        <edit-button
                            :tooltip="$t('actions.copy')"
                            color="brandAlt"
                            icon="content_copy"
                            @execute="copyItem(item)"
                        ></edit-button>
                    </template>
                </d-table>
            </template>
        </data-iterator>

        <confirm-modal
            :value="showAdminEnableModal"
            :text="
                $t('texts.confirm_restaurant_admin_enable', {
                    restaurant: itemSelectedForAdminChange
                        ? itemSelectedForAdminChange.name
                        : '',
                })
            "
            :processing="processingAdminChange"
            @onApprove="restaurantAdminEnable"
            @onCancel="resetAdminCheckboxes"
        ></confirm-modal>
        <confirm-modal
            :value="showAdminDisableModal"
            :text="
                $t('texts.confirm_restaurant_admin_disable', {
                    restaurant: itemSelectedForAdminChange
                        ? itemSelectedForAdminChange.name
                        : '',
                })
            "
            :processing="processingAdminChange"
            @onApprove="restaurantAdminDisable"
            @onCancel="resetAdminCheckboxes"
        ></confirm-modal>
    </table-container>
</template>

<script>
import CloneDeep from 'lodash/cloneDeep'
import routeType from '@/router/routeType'
import RestApiType from '@/api/RestApiType'
import DataIteratorMixin from '@/components/mixins/DataIteratorMixin'
import DataTableMixin from '@/components/mixins/DataTableMixin'
import CheckboxInput from '@/components/form/Inputs/CheckboxInput'
import ConfirmModal from '@/components/confirm/ConfirmModal'
import RestaurantsApiClient from '@/api/RestApi/RestaurantsApiClient'
import { copyRestaurantFElink } from '@/services/Restaurants/RestaurantFrontendLink'
import Headers from './mixins/Headers'
import ListFilters from './mixins/ListFilters'
import AuthMixin from '@/components/mixins/AuthMixin'
import Notification from '@/services/Notification/Notification'
import ModalActivateForToday from '@/components/pages/meals/ModalActivateForToday'

export default {
    components: { ConfirmModal, CheckboxInput, ModalActivateForToday },
    mixins: [
        DataIteratorMixin,
        DataTableMixin,
        Headers,
        ListFilters,
        AuthMixin,
    ],
    data() {
        return {
            api: RestApiType.RESTAURANTS,
            showAdminEnableModal: false,
            showAdminDisableModal: false,
            processingAdminChange: false,
            itemSelectedForAdminChange: null,
            showAdminCheckboxes: true,
        }
    },
    computed: {
        tableName() {
            return this.$t('labels.restaurants')
        },
    },
    methods: {
        getEditLink(item, tab = 0) {
            return {
                name: routeType.RESTAURANT_EDIT,
                params: { id: item.id },
                query: { tab: tab },
            }
        },

        copyItem(item) {
            RestaurantsApiClient.clone(item.id).then(() => {
                this.updateFilters({ _search: item.name })
            })
        },

        confirmRestaurantAdminEnable(item) {
            this.itemSelectedForAdminChange = CloneDeep(item)
            this.showAdminEnableModal = true
        },

        confirmRestaurantAdminDisable(item) {
            this.itemSelectedForAdminChange = CloneDeep(item)
            this.showAdminDisableModal = true
        },

        restaurantAdminEnable() {
            this.processingAdminChange = true
            RestaurantsApiClient.restaurantAdminEnable(
                this.itemSelectedForAdminChange.id
            )
                .then(() => {
                    Notification.restaurantAdminEnabled(
                        'labels.restaurant',
                        this.itemSelectedForAdminChange.name
                    )
                })
                .finally(() => {
                    this.showAdminEnableModal = false
                    this.processingAdminChange = false
                    this.reloadAfterActivationChanges()
                })
        },

        restaurantAdminDisable() {
            this.processingAdminChange = true
            RestaurantsApiClient.restaurantAdminDisable(
                this.itemSelectedForAdminChange.id
            )
                .then(() => {
                    Notification.restaurantAdminDisabled(
                        'labels.restaurant',
                        this.itemSelectedForAdminChange.name
                    )
                })
                .catch((e) => {
                    if (e?.response?.data?.violations instanceof Array) {
                        const messages = []
                        e.response.data.violations.forEach((v) => {
                            messages.push(`${v.propertyPath}: ${v.message}`)
                        })
                        Notification.requestError(messages.join(','))
                    }
                })
                .finally(() => {
                    this.showAdminDisableModal = false
                    this.processingAdminChange = false
                    this.reloadAfterActivationChanges()
                })
        },

        resetAdminCheckboxes() {
            this.showAdminEnableModal = false
            this.showAdminDisableModal = false
            this.showAdminCheckboxes = false

            this.$nextTick(() => {
                this.showAdminCheckboxes = true
            })
        },

        reloadAfterActivationChanges() {
            this.$refs.iterator.updateItems(true).then(() => {
                this.resetAdminCheckboxes()
            })
        },

        copyRestaurantFElink(item) {
            copyRestaurantFElink(item)
        },
    },
}
</script>
